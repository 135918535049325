import Swal from 'sweetalert2';
import {
  FILTRAR_NOTIFICACOES,
  LISTAR_NOTIFICACOES,
  LISTAR_NOTIFICACOES_ERRO,
  LISTAR_NOTIFICACOES_SUCESSO,
} from './constants';

const INIT_STATE = {
  notificacoes: {
    result: [],
    total: 0,
  },
  filtros: {},
  carregando: false,
};

const Notificacoes = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LISTAR_NOTIFICACOES:
      return { ...state, carregando: true };
    case FILTRAR_NOTIFICACOES:
      return { ...state, filtros: action.payload, carregando: true };
    case LISTAR_NOTIFICACOES_SUCESSO:
      return { ...state, notificacoes: action.payload, carregando: false };
    case LISTAR_NOTIFICACOES_ERRO:
      Swal.fire({
        icon: 'error',
        title: 'Erro ao buscar Notificacoes',
        timer: 2000,
        text: action.payload,
      });
      return { ...state, notificacoes: [], carregando: false };

    default:
      return state;
  }
};

export default Notificacoes;
