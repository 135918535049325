// @flow
import { combineReducers } from 'redux';
import Relatorios from '../components/tabelaRelatorios/store/reducers';
import AberturaFechamentoPDV from '../pages/abertura-fechamento-pdv/store/reducers';
import AliquotasImpostosServicos from '../pages/aliquotas-impostos-servicos/store/reducers';
import Alvos from '../pages/alvos/store/reducers';
import Armadilhas from '../pages/armadilhas/store/reducers';
import AtividadesControleProdutividade from '../pages/atividade-controle-produtividade/store/reducers';
import Atividades from '../pages/atividades/store/reducers';
import Auth from '../pages/auth/store/reducers';
import CaixasBancos from '../pages/caixa-bancos/store/reducers';
import CamarasFria from '../pages/camaras-fria/store/reducers';
import CategoriasProdutosAgro from '../pages/categoria-produtos-agro/store/reducers';
import CentrosCustos from '../pages/centros-custos/store/reducers';
import CEST from '../pages/cest/store/reducers';
import CFOPs from '../pages/cfop/store/reducers';
import Cidades from '../pages/cidades/store/reducers';
import ClassesProdutos from '../pages/classes-produtos/store/reducers';
import CNAEs from '../pages/cnae/store/reducers';
import CodigosBarrasEAN from '../pages/codigos-barras-ean/store/reducers';
import ConceitosQualidade from '../pages/conceitos-qualidade/store/reducers';
import Configuracoes from '../pages/configuracoes/store/reducers';
import ContasPagar from '../pages/contas-pagar/store/reducers';
import ContasReceber from '../pages/contas-receber/store/reducers';
import Contas from '../pages/contas/store/reducers';
import Contratos from '../pages/contratos/store/reducers';
import CoresProdutos from '../pages/cores-produtos/store/reducers';
import Cotacoes from '../pages/cotacoes/store/reducers';
import CultivosProtegidos from '../pages/cultivos-protegidos/store/reducers';
import Culturas from '../pages/culturas/store/reducers';
import DanosDefeitos from '../pages/danos-defeitos/store/reducers';
import DepartamentoProduto from '../pages/departamentos-produtos/store/reducers';
import Depositos from '../pages/depositos/store/reducers';
import Empresas from '../pages/empresas/store/reducers';
import EntradaFrutas from '../pages/entrada-frutas/store/reducers';
import EntradaSaidaProduto from '../pages/entrada-saida-produtos/store/reducers';
import EquipesAtividades from '../pages/equipes-atividades/store/reducers';
import Estados from '../pages/estados/store/reducers';
import EstagiosFenologicos from '../pages/estagios-fenologicos/store/reducers';
import FormasPagamentos from '../pages/formas-pagamentos/store/reducers';
import GruposAtividades from '../pages/grupos-atividades/store/reducers';
import GruposCentrosCustos from '../pages/grupos-centros-custos/store/reducers';
import GruposEconomicosPessoas from '../pages/grupos-economicos-pessoas/store/reducers';
import GruposFinanceiros from '../pages/grupos-financeiros/store/reducers';
import GruposMaquinasImplementos from '../pages/grupos-maquinas-implementos/store/reducers';
import GruposPessoas from '../pages/grupos-pessoas/store/reducers';
import GrupoProduto from '../pages/grupos-produtos/store/reducers';
import GruposQuimicos from '../pages/grupos-quimicos/store/reducers';
import GrupoServico from '../pages/grupos-servico/store/reducers';
import HistoricosPadrao from '../pages/historicos-padrao/store/reducers';
import InformacoesNutricionais from '../pages/informacoes-nutricionais/store/reducers';
import Integradores from '../pages/integradores/store/reducers';
import LancamentosAtividades from '../pages/lancamentos-atividades/store/reducers';
import LancamentosCustos from '../pages/lancamentos-custos/store/reducers';
import ListasPrecos from '../pages/listas-precos/store/reducers';
import ManutencaoMaquinasVeiculos from '../pages/manutencao-maquinas-veiculos/store/reducers';
import MaquinasImplementos from '../pages/maquinas-implementos/store/reducers';
import MarcasProdutos from '../pages/marcas-produtos/store/reducers';
import MarcasVeiculos from '../pages/marcas-veiculos/store/reducers';
import MesasComandas from '../pages/mesas-comandas/store/reducers';
import ModelosEtiquetas from '../pages/modelos-etiquetas/store/reducers';
import ModelosRelatorios from '../pages/modelos-relatorios/store/reducers';
import Modulos from '../pages/modulos/store/reducers';
import Moedas from '../pages/moedas/store/reducers';
import MotivosCancelamentosContratos from '../pages/motivos-cancelamentos-contratos/store/reducers';
import MovimentacoesProdutos from '../pages/movimentacao-produtos/store/reducers';
import NaturezaOperacoes from '../pages/natureza-operacao/store/reducers';
import NCMs from '../pages/ncm/store/reducers';
import NotasEntradas from '../pages/notas-entradas/store/reducers';
import NotasFiscaisConsumidor from '../pages/notas-fiscais-consumidor/store/reducers';
import NotasFiscaisServicos from '../pages/notas-fiscais-servico/store/reducers';
import NotasFiscais from '../pages/notas-fiscais/store/reducers';
import Notificacoes from '../pages/notificacoes/store/reducers';
import ObservacoesAtividades from '../pages/observacoes-atividades/store/reducers';
import OcorrenciasFinanceiras from '../pages/ocorrencias-financeiras/store/reducers';
import OpcoesCadastrais from '../pages/opcoes-cadastrais/store/reducers';
import Orcamentos from '../pages/orcamentos/store/reducers';
import OrdensCompra from '../pages/ordens-compra/store/reducers';
import Organizacoes from '../pages/organizacoes/store/reducers';
import Paises from '../pages/paises/store/reducers';
import Parametros from '../pages/parametros/store/reducers';
import Pedidos from '../pages/pedidos/store/reducers';
import PerfilAcesso from '../pages/perfil-acesso/store/reducers';
import Permissoes from '../pages/permissoes/store/reducers';
import Pessoas from '../pages/pessoas/store/reducers';
import PlanoContas from '../pages/plano-contas/store/reducers';
import PortaEnxertos from '../pages/porta-enxertos/store/reducers';
import PrevisoesColheita from '../pages/previsoes-colheita/store/reducers';
import PrincipiosAtivos from '../pages/principios-ativo-produto/store/reducers';
import Produtos from '../pages/produtos/store/reducers';
import Programa from '../pages/programas/store/reducers';
import Promocoes from '../pages/promocoes/store/reducers';
import Propriedades from '../pages/propriedades/store/reducers';
import RecomendacoesTecnicas from '../pages/recomendacoes-tecnicas/store/reducers';
import RegistroEtiquetasEntradaFrutas from '../pages/registro-etiquetas-entrada-frutas/store/reducers';
import RegistrosClimaticos from '../pages/registros-climaticos/store/reducers';
import RelatoriosCadernoCampo from '../pages/relatorio-caderno-campo/store/reducers';
import RelatoriosCustos from '../pages/relatorios-custos/store/reducers';
import RelatoriosEstoque from '../pages/relatorios-estoque/store/reducers';
import RelatoriosLog from '../pages/relatorios-logs/store/reducers';
import RelatoriosMonitoramentoCarencia from '../pages/relatorios-monitoramento-carencia/store/reducers';
import Revenda from '../pages/revenda/store/reducers';
import Safras from '../pages/safras/store/reducers';
import Segmentos from '../pages/segmentos/store/reducers';
import Servicos from '../pages/servicos/store/reducers';
import SetoresMesas from '../pages/setores-mesas/store/reducers';
import SistemasConducao from '../pages/sistemas-conducao/store/reducers';
import SistemasIrrigacao from '../pages/sistemas-irrigacao/store/reducers';
import SistemasProducao from '../pages/sistemas-producao/store/reducers';
import Solucoes from '../pages/solucoes/store/reducers';
import SubGruposFinanceiros from '../pages/sub-grupos-financeiros/store/reducers';
import SubGrupoProduto from '../pages/sub-grupos-produtos/store/reducers';
import TamanhoProdutosAgro from '../pages/tamanho-produtos-agro/store/reducers';
import TamanhoProdutos from '../pages/tamanho-produtos/store/reducers';
import TiposCobrancas from '../pages/tipos-cobrancas/store/reducers';
import TransferenciaDeposito from '../pages/transferencia-depositos/store/reducers';
import TransferenciasCaixaBancos from '../pages/transferencias-caixa-bancos/store/reducers';
import TributacaoFederalPisCofins from '../pages/tributacao-federal-pis-cofins/store/reducers';
import TributacoesEstaduais from '../pages/tributacoes-estaduais/store/reducers';
import TributacoesFederaisIpi from '../pages/tributacoes-federais-ipi/store/reducers';
import TributacoesServicos from '../pages/tributacoes-servicos/store/reducers';
import UnidadeMedidaProduto from '../pages/unidades-medidas-produtos/store/reducers';
import UnidadesProdutivas from '../pages/unidades-produtivas/store/reducers';
import Usuario from '../pages/usuarios/store/reducers';
import ValoresMaoObra from '../pages/valores-mao-obra/store/reducers';
import VariedadesClones from '../pages/variedades-clones/store/reducers';
import Variedades from '../pages/variedades/store/reducers';
import Vasilhames from '../pages/vasilhames/store/reducers';
import Veiculos from '../pages/veiculos/store/reducers';
import VendasColheitas from '../pages/vendas-colheitas/store/reducers';
import VisitasTecnicas from '../pages/visitas-tecnicas/store/reducers';
import AppMenu from './appMenu/reducers';
import Layout from './layout/reducers';
import TableConfig, { MenuAction } from './table/reducers';

const reducers = combineReducers({
  Auth,
  AppMenu,
  Layout,
  TableConfig,
  MenuAction,
  Usuario,
  PerfilAcesso,
  Programa,
  Modulos,
  Solucoes,
  GruposEconomicosPessoas,
  Parametros,
  Segmentos,
  Organizacoes,
  Permissoes,
  Paises,
  Estados,
  GruposPessoas,
  Empresas,
  Pessoas,
  CNAEs,
  Cidades,
  NCMs,
  OpcoesCadastrais,
  CFOPs,
  SistemasIrrigacao,
  SistemasProducao,
  CultivosProtegidos,
  Revenda,
  PortaEnxertos,
  Variedades,
  VariedadesClones,
  CEST,
  Culturas,
  GrupoProduto,
  SubGrupoProduto,
  MarcasProdutos,
  UnidadeMedidaProduto,
  DepartamentoProduto,
  ClassesProdutos,
  GruposQuimicos,
  EstagiosFenologicos,
  SistemasConducao,
  GruposAtividades,
  InformacoesNutricionais,
  CoresProdutos,
  TamanhoProdutos,
  CategoriasProdutosAgro,
  Atividades,
  TamanhoProdutosAgro,
  Alvos,
  GruposCentrosCustos,
  CentrosCustos,
  PrincipiosAtivos,
  Produtos,
  Propriedades,
  ObservacoesAtividades,
  EquipesAtividades,
  Armadilhas,
  DanosDefeitos,
  LancamentosAtividades,
  RegistrosClimaticos,
  Relatorios,
  AtividadesControleProdutividade,
  GruposMaquinasImplementos,
  MaquinasImplementos,
  UnidadesProdutivas,
  RecomendacoesTecnicas,
  PrevisoesColheita,
  Safras,
  TributacoesEstaduais,
  TributacoesFederaisIpi,
  ConceitosQualidade,
  Integradores,
  EntradaSaidaProduto,
  GrupoServico,
  Servicos,
  Contas,
  TributacaoFederalPisCofins,
  RelatoriosCadernoCampo,
  RelatoriosEstoque,
  CamarasFria,
  Depositos,
  VendasColheitas,
  RelatoriosMonitoramentoCarencia,
  LancamentosCustos,
  GruposFinanceiros,
  SubGruposFinanceiros,
  TiposCobrancas,
  FormasPagamentos,
  RelatoriosCustos,
  ValoresMaoObra,
  ModelosEtiquetas,
  MarcasVeiculos,
  VisitasTecnicas,
  Veiculos,
  NaturezaOperacoes,
  ManutencaoMaquinasVeiculos,
  NotasFiscais,
  OrdensCompra,
  Pedidos,
  Orcamentos,
  NotasEntradas,
  Moedas,
  Cotacoes,
  ListasPrecos,
  Promocoes,
  CodigosBarrasEAN,
  Vasilhames,
  EntradaFrutas,
  ModelosRelatorios,
  TransferenciaDeposito,
  HistoricosPadrao,
  RegistroEtiquetasEntradaFrutas,
  PlanoContas,
  MovimentacoesProdutos,
  ContasPagar,
  ContasReceber,
  TransferenciasCaixaBancos,
  CaixasBancos,
  NotasFiscaisConsumidor,
  Contratos,
  NotasFiscaisServicos,
  AliquotasImpostosServicos,
  Configuracoes,
  AberturaFechamentoPDV,
  OcorrenciasFinanceiras,
  MotivosCancelamentosContratos,
  TributacoesServicos,
  SetoresMesas,
  MesasComandas,
  RelatoriosLog,
  Notificacoes,
});

export default reducers;
export type State = ReturnType<typeof reducers>;
