import { AxiosResponse } from 'axios';
import api from '../../services/api';
import { montarQueryString } from '../../utils';
import { INotificacaoCadastro, INotificacaoConsulta } from './interfaces';

export const obterNotificacoesAsync = (
  query,
): Promise<AxiosResponse<{ result: INotificacaoConsulta[]; total: number }>> => {
  const queryStr = montarQueryString(query);
  return api.request({
    url: `notificacao${queryStr}`,
    method: 'GET',
  });
};

export const obterNotificacaoAsync = (id: number): Promise<AxiosResponse<INotificacaoConsulta>> => {
  return api.request({
    url: `notificacao/${id}`,
    method: 'GET',
  });
};

export const criarNotificacoesAsync = (
  notificacao: INotificacaoCadastro,
): Promise<AxiosResponse<INotificacaoConsulta>> => {
  return api.request({
    url: `notificacao`,
    data: notificacao,
    method: 'POST',
  });
};

export const atualizarNotificacoesAsync = (
  id: number,
  notificacao: INotificacaoCadastro,
): Promise<AxiosResponse<INotificacaoConsulta>> => {
  return api.request({
    url: `notificacao/${id}`,
    data: notificacao,
    method: 'PATCH',
  });
};

export const removerNotificacoesEmMassaAsync = (ids: number[]): Promise<AxiosResponse<{ affected: number }>> => {
  return api.request({
    url: `notificacao`,
    data: { ids },
    method: 'DELETE',
  });
};

export const removerNotificacoesAsync = (id: number): Promise<AxiosResponse<{ affected: number }>> =>
  api.request({
    url: `notificacao/${id}`,
    method: 'DELETE',
  });
