import * as FeatherIcon from 'react-feather';
import { IMenu } from '../../interfaces/menu';
import { PermissaoCodigo } from '../enums/permissao-codigo.enum';
import { ProgramaCodigo } from '../enums/programa-codigo.enum';
import { RoleCodigo } from '../enums/role-codigo.enum';

export const MensageriaMenus: IMenu = {
  roles: Object.values(RoleCodigo),
  name: 'Mensageria',
  children: [
    {
      programaCod: ProgramaCodigo.Notificacoes,
      name: 'Notificações',
      path: '/notificacoes/listar',
      icon: FeatherIcon.Bell,
      component: 'listarNotificacoes',
      children: [
        {
          permissaoCod: PermissaoCodigo.Acesso,
          path: '/notificacoes/listar',
          component: 'listarNotificacoes',
        },
        {
          permissaoCod: PermissaoCodigo.Incluir,
          path: '/notificacoes/criar',
          component: 'notificacoes',
        },
      ],
    },

    {
      programaCod: ProgramaCodigo.Emails,
      name: 'E-mail',
      path: '/emails/emitir',
      icon: FeatherIcon.Mail,
      component: 'emitirEmails',
    },
  ],
};

// import * as FeatherIcon from 'react-feather';
// import { IMenu } from '../../interfaces/menu';
// import { PermissaoCodigo } from '../enums/permissao-codigo.enum';
// import { ProgramaCodigo } from '../enums/programa-codigo.enum';
// import { RoleCodigo } from '../enums/role-codigo.enum';

// export const MensageriaMenus: IMenu = {
//   roles: Object.values(RoleCodigo),
//   name: 'Mensageria',
//   children: [
//     {
//       permissaoCod: PermissaoCodigo.Acesso,
//       name: 'Minhas notificações',
//       path: '/notificacoes/listar',
//       icon: FeatherIcon.Bell,
//       component: 'listarNotificacoes',
//     },
//     {
//       programaCod: ProgramaCodigo.Notificacoes,
//       permissaoCod: PermissaoCodigo.Incluir,
//       name: 'Emitir notificação',
//       path: '/notificacoes/criar',
//       icon: FeatherIcon.Bell,
//       component: 'notificacoes',
//     },
//   ],
// };

// // export const MensageriaMenus: IMenu = {
// //   roles: Object.values(RoleCodigo),
// //   name: 'Mensageria',
// //   children: [
// //     {
// //       permissaoCod: PermissaoCodigo.Acesso,
// //       name: 'Minhas notificações',
// //       path: '/notificacoes/listar',
// //       icon: FeatherIcon.Bell,
// //       component: 'listarNotificacoes',
// //     },
// //     {
// //       permissaoCod: PermissaoCodigo.Incluir,
// //       name: 'Emitir notificação',
// //       path: '/notificacoes/criar',
// //       icon: FeatherIcon.Bell,
// //       component: 'notificacoes',
// //     },
// //   ],
// // };
