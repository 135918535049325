import jwt_decode from 'jwt-decode';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { programas } from '../constants/permissoes';
import { getToken, getUserPermissions, isUserAuthenticated } from '../helpers/authUtils';
import AlterarSenha from '../pages/auth/AlterarSenha';
import AcessoRapido from '../pages/usuarios/acessoRapido';
import Perfil from '../pages/usuarios/perfil';

// public
import RastreabilidadeAgro from '../pages/rastreabilidade/agro';
import RastreabilidadePropriedade from '../pages/rastreabilidade/propriedade';

// auth
const Login = React.lazy(() => import('../pages/auth/Login'));
const Logout = React.lazy(() => import('../pages/auth/Logout'));
const AuthDoisFatores = React.lazy(() => import('../pages/auth/AuthDoisFatores'));
const ForgetPassword = React.lazy(() => import('../pages/auth/ForgetPassword'));
const ResetPassword = React.lazy(() => import('../pages/auth/resetPassword'));
const SelecionarEmpresa = React.lazy(() => import('../pages/auth/SelecionarEmpresa'));

// private
const loggedRoutes = {
  dashboard: React.lazy(() => import('../pages/dashboard')),
  modulos: React.lazy(() => import('../pages/modulos/cadastro')),
  listarModulos: React.lazy(() => import('../pages/modulos/lista')),
  programas: React.lazy(() => import('../pages/programas/cadastro')),
  programasListar: React.lazy(() => import('../pages/programas/lista')),
  solucoes: React.lazy(() => import('../pages/solucoes/cadastro')),
  listarSolucoes: React.lazy(() => import('../pages/solucoes/lista')),
  usuarios: React.lazy(() => import('../pages/usuarios/cadastro')),
  listarUsuarios: React.lazy(() => import('../pages/usuarios/lista')),
  perfilAcesso: React.lazy(() => import('../pages/perfil-acesso/cadastro')),
  listaPerfilAcesso: React.lazy(() => import('../pages/perfil-acesso/lista')),
  parametrosListar: React.lazy(() => import('../pages/parametros/lista')),
  parametrosCadastro: React.lazy(() => import('../pages/parametros/cadastro')),
  gruposEconomicosPessoas: React.lazy(() => import('../pages/grupos-economicos-pessoas/cadastro')),
  listarGruposEconomicosPessoas: React.lazy(() => import('../pages/grupos-economicos-pessoas/lista')),
  segmentosLista: React.lazy(() => import('../pages/segmentos/lista')),
  organizacoes: React.lazy(() => import('../pages/organizacoes/cadastro')),
  listarOrganizacoes: React.lazy(() => import('../pages/organizacoes/lista')),
  segmentosCadastro: React.lazy(() => import('../pages/segmentos/cadastro')),
  permissoes: React.lazy(() => import('../pages/permissoes/cadastro')),
  permissoesLista: React.lazy(() => import('../pages/permissoes/lista')),
  paises: React.lazy(() => import('../pages/paises/cadastro')),
  listarPaises: React.lazy(() => import('../pages/paises/lista')),
  estados: React.lazy(() => import('../pages/estados/cadastro')),
  listarEstados: React.lazy(() => import('../pages/estados/lista')),
  gruposPessoas: React.lazy(() => import('../pages/grupos-pessoas/cadastro')),
  listarGruposPessoas: React.lazy(() => import('../pages/grupos-pessoas/lista')),
  empresasLista: React.lazy(() => import('../pages/empresas/lista')),
  empresasCadastro: React.lazy(() => import('../pages/empresas/cadastro')),
  CNAEs: React.lazy(() => import('../pages/cnae/cadastro')),
  listarCNAEs: React.lazy(() => import('../pages/cnae/lista')),
  cidades: React.lazy(() => import('../pages/cidades/cadastro')),
  listarCidades: React.lazy(() => import('../pages/cidades/lista')),
  NCMs: React.lazy(() => import('../pages/ncm/cadastro')),
  listarNCMs: React.lazy(() => import('../pages/ncm/lista')),
  CESTs: React.lazy(() => import('../pages/cest/cadastro')),
  listarCESTs: React.lazy(() => import('../pages/cest/lista')),
  pessoasLista: React.lazy(() => import('../pages/pessoas/lista')),
  pessoasCadastro: React.lazy(() => import('../pages/pessoas/cadastro')),
  listarOpcoesCadastrais: React.lazy(() => import('../pages/opcoes-cadastrais/lista')),
  opcoesCadastrais: React.lazy(() => import('../pages/opcoes-cadastrais/cadastro')),
  CFOPs: React.lazy(() => import('../pages/cfop/cadastro')),
  listarCFOPs: React.lazy(() => import('../pages/cfop/lista')),
  sistemasProducao: React.lazy(() => import('../pages/sistemas-producao/cadastro')),
  listarSistemasProducao: React.lazy(() => import('../pages/sistemas-producao/lista')),
  sistemasIrrigacao: React.lazy(() => import('../pages/sistemas-irrigacao/cadastro')),
  listarSistemasIrrigacao: React.lazy(() => import('../pages/sistemas-irrigacao/lista')),
  cultivosProtegidos: React.lazy(() => import('../pages/cultivos-protegidos/cadastro')),
  listarCultivosProtegidos: React.lazy(() => import('../pages/cultivos-protegidos/lista')),
  revenda: React.lazy(() => import('../pages/revenda/cadastro')),
  listarRevenda: React.lazy(() => import('../pages/revenda/lista')),
  portaEnxertos: React.lazy(() => import('../pages/porta-enxertos/cadastro')),
  listarPortaEnxertos: React.lazy(() => import('../pages/porta-enxertos/lista')),
  variedades: React.lazy(() => import('../pages/variedades/cadastro')),
  listarVariedades: React.lazy(() => import('../pages/variedades/lista')),
  culturas: React.lazy(() => import('../pages/culturas/cadastro')),
  listarCulturas: React.lazy(() => import('../pages/culturas/lista')),
  variedadesClones: React.lazy(() => import('../pages/variedades-clones/cadastro')),
  listarVariedadesClones: React.lazy(() => import('../pages/variedades-clones/lista')),
  gruposProduto: React.lazy(() => import('../pages/grupos-produtos/cadastro')),
  listarGruposProduto: React.lazy(() => import('../pages/grupos-produtos/lista')),
  subGruposProduto: React.lazy(() => import('../pages/sub-grupos-produtos/cadastro')),
  listarSubGruposProduto: React.lazy(() => import('../pages/sub-grupos-produtos/lista')),
  marcasProdutos: React.lazy(() => import('../pages/marcas-produtos/cadastro')),
  listarMarcasProdutos: React.lazy(() => import('../pages/marcas-produtos/lista')),
  unidadesMedidasProdutos: React.lazy(() => import('../pages/unidades-medidas-produtos/cadastro')),
  listarUnidadesMedidasProdutos: React.lazy(() => import('../pages/unidades-medidas-produtos/lista')),
  tributacoesFederalPisCofins: React.lazy(() => import('../pages/tributacao-federal-pis-cofins/cadastro')),
  listaTributacaoFederalPisCofins: React.lazy(() => import('../pages/tributacao-federal-pis-cofins/lista')),
  departamentoProdutos: React.lazy(() => import('../pages/departamentos-produtos/cadastro')),
  listarDepartamentoProdutos: React.lazy(() => import('../pages/departamentos-produtos/lista')),
  classesProdutos: React.lazy(() => import('../pages/classes-produtos/cadastro')),
  listarClassesProdutos: React.lazy(() => import('../pages/classes-produtos/lista')),
  gruposQuimicos: React.lazy(() => import('../pages/grupos-quimicos/cadastro')),
  listarGruposQuimicos: React.lazy(() => import('../pages/grupos-quimicos/lista')),
  estagiosFenologicos: React.lazy(() => import('../pages/estagios-fenologicos/cadastro')),
  listarEstagiosFenologicos: React.lazy(() => import('../pages/estagios-fenologicos/lista')),
  sistemasConducao: React.lazy(() => import('../pages/sistemas-conducao/cadastro')),
  listarSistemasConducao: React.lazy(() => import('../pages/sistemas-conducao/lista')),
  listarGruposAtividades: React.lazy(() => import('../pages/grupos-atividades/lista')),
  gruposAtividades: React.lazy(() => import('../pages/grupos-atividades/cadastro')),
  listarInformacoesNutricionais: React.lazy(() => import('../pages/informacoes-nutricionais/lista')),
  informacoesNutricionais: React.lazy(() => import('../pages/informacoes-nutricionais/cadastro')),
  listarCoresProdutos: React.lazy(() => import('../pages/cores-produtos/lista')),
  coresProdutos: React.lazy(() => import('../pages/cores-produtos/cadastro')),
  listarTamanhoProdutos: React.lazy(() => import('../pages/tamanho-produtos/lista')),
  tamanhoProdutos: React.lazy(() => import('../pages/tamanho-produtos/cadastro')),
  listarCategoriasProdutosAgro: React.lazy(() => import('../pages/categoria-produtos-agro/lista')),
  categoriasProdutosAgro: React.lazy(() => import('../pages/categoria-produtos-agro/cadastro')),
  listarAtividades: React.lazy(() => import('../pages/atividades/lista')),
  atividades: React.lazy(() => import('../pages/atividades/cadastro')),
  listarTamanhoProdutosAgro: React.lazy(() => import('../pages/tamanho-produtos-agro/lista')),
  tamanhoProdutosAgro: React.lazy(() => import('../pages/tamanho-produtos-agro/cadastro')),
  listarAlvos: React.lazy(() => import('../pages/alvos/lista')),
  alvos: React.lazy(() => import('../pages/alvos/cadastro')),
  listarGruposCentrosCustos: React.lazy(() => import('../pages/grupos-centros-custos/lista')),
  gruposCentrosCustos: React.lazy(() => import('../pages/grupos-centros-custos/cadastro')),
  listarCentrosCustos: React.lazy(() => import('../pages/centros-custos/lista')),
  centrosCustos: React.lazy(() => import('../pages/centros-custos/cadastro')),
  principiosAtivos: React.lazy(() => import('../pages/principios-ativo-produto/cadastro')),
  listarPrincipiosAtivos: React.lazy(() => import('../pages/principios-ativo-produto/lista')),
  produtos: React.lazy(() => import('../pages/produtos/cadastro')),
  listarProdutos: React.lazy(() => import('../pages/produtos/lista')),
  listarPropriedades: React.lazy(() => import('../pages/propriedades/lista')),
  propriedades: React.lazy(() => import('../pages/propriedades/cadastro')),
  listarObservacoesAtividades: React.lazy(() => import('../pages/observacoes-atividades/lista')),
  observacoesAtividades: React.lazy(() => import('../pages/observacoes-atividades/cadastro')),
  listarEquipesAtividades: React.lazy(() => import('../pages/equipes-atividades/lista')),
  equipesAtividades: React.lazy(() => import('../pages/equipes-atividades/cadastro')),
  listarArmadilhas: React.lazy(() => import('../pages/armadilhas/lista')),
  armadilhas: React.lazy(() => import('../pages/armadilhas/cadastro')),
  listarDanosDefeitos: React.lazy(() => import('../pages/danos-defeitos/lista')),
  danosDefeitos: React.lazy(() => import('../pages/danos-defeitos/cadastro')),
  listarLancamentosAtividades: React.lazy(() => import('../pages/lancamentos-atividades/lista')),
  etiquetaLancamentosAtividades: React.lazy(() => import('../pages/lancamentos-atividades/etiqueta')),
  etiquetaPersonalizadaLancamentosAtividades: React.lazy(() =>
    import('../pages/lancamentos-atividades/etiqueta-personalizada'),
  ),
  lancamentosAtividades: React.lazy(() => import('../pages/lancamentos-atividades/cadastro')),
  listarRegistrosClimaticos: React.lazy(() => import('../pages/registros-climaticos/lista')),
  registrosClimaticos: React.lazy(() => import('../pages/registros-climaticos/cadastro')),
  listarAtividadesControleProdutividade: React.lazy(() => import('../pages/atividade-controle-produtividade/lista')),
  atividadesControleProdutividade: React.lazy(() => import('../pages/atividade-controle-produtividade/cadastro')),
  listarGruposMaquinasImplementos: React.lazy(() => import('../pages/grupos-maquinas-implementos/lista')),
  gruposMaquinasImplementos: React.lazy(() => import('../pages/grupos-maquinas-implementos/cadastro')),
  listarMaquinasImplementos: React.lazy(() => import('../pages/maquinas-implementos/lista')),
  maquinasImplementos: React.lazy(() => import('../pages/maquinas-implementos/cadastro')),
  listarUnidadesProdutivas: React.lazy(() => import('../pages/unidades-produtivas/lista')),
  unidadesProdutivas: React.lazy(() => import('../pages/unidades-produtivas/cadastro')),
  listarRecomendacoesTecnicas: React.lazy(() => import('../pages/recomendacoes-tecnicas/lista')),
  recomendacoesTecnicas: React.lazy(() => import('../pages/recomendacoes-tecnicas/cadastro')),
  listarPrevisoesColheita: React.lazy(() => import('../pages/previsoes-colheita/lista')),
  previsoesColheita: React.lazy(() => import('../pages/previsoes-colheita/cadastro')),
  listarSafras: React.lazy(() => import('../pages/safras/lista')),
  safras: React.lazy(() => import('../pages/safras/cadastro')),
  listarTributacoesEstaduais: React.lazy(() => import('../pages/tributacoes-estaduais/lista')),
  tributacoesEstaduais: React.lazy(() => import('../pages/tributacoes-estaduais/cadastro')),
  listarTributacoesFederaisIpi: React.lazy(() => import('../pages/tributacoes-federais-ipi/lista')),
  tributacoesFederaisIpi: React.lazy(() => import('../pages/tributacoes-federais-ipi/cadastro')),
  listarConceitosQualidade: React.lazy(() => import('../pages/conceitos-qualidade/lista')),
  conceitosQualidade: React.lazy(() => import('../pages/conceitos-qualidade/cadastro')),
  gruposServico: React.lazy(() => import('../pages/grupos-servico/cadastro')),
  listarGruposServico: React.lazy(() => import('../pages/grupos-servico/lista')),
  servicos: React.lazy(() => import('../pages/servicos/cadastro')),
  listarServicos: React.lazy(() => import('../pages/servicos/lista')),
  listarContas: React.lazy(() => import('../pages/contas/lista')),
  contas: React.lazy(() => import('../pages/contas/cadastro')),
  listarIntegradores: React.lazy(() => import('../pages/integradores/lista')),
  integradores: React.lazy(() => import('../pages/integradores/cadastro')),
  listarCadernoCampo: React.lazy(() => import('../pages/relatorio-caderno-campo')),
  entradaSaidaProdutos: React.lazy(() => import('../pages/entrada-saida-produtos/cadastro')),
  listarEntradaSaidaProdutos: React.lazy(() => import('../pages/entrada-saida-produtos/lista')),
  relatoriosEstoque: React.lazy(() => import('../pages/relatorios-estoque')),
  relatoriosLogs: React.lazy(() => import('../pages/relatorios-logs')),
  listarCamarasFria: React.lazy(() => import('../pages/camaras-fria/lista')),
  camarasFria: React.lazy(() => import('../pages/camaras-fria/cadastro')),
  listarDepositos: React.lazy(() => import('../pages/depositos/lista')),
  depositos: React.lazy(() => import('../pages/depositos/cadastro')),
  listarVendasColheitas: React.lazy(() => import('../pages/vendas-colheitas/lista')),
  VendasColheitas: React.lazy(() => import('../pages/vendas-colheitas/cadastro')),
  relatoriosMonitoramentoCarencia: React.lazy(() => import('../pages/relatorios-monitoramento-carencia')),
  atualizadorCustos: React.lazy(() => import('../pages/atualizador-custos')),
  listarLancamentosCustos: React.lazy(() => import('../pages/lancamentos-custos/lista')),
  lancamentosCustos: React.lazy(() => import('../pages/lancamentos-custos/cadastro')),
  listarGruposFinanceiros: React.lazy(() => import('../pages/grupos-financeiros/lista')),
  gruposFinanceiros: React.lazy(() => import('../pages/grupos-financeiros/cadastro')),
  listarSubGruposFinanceiros: React.lazy(() => import('../pages/sub-grupos-financeiros/lista')),
  subGruposFinanceiros: React.lazy(() => import('../pages/sub-grupos-financeiros/cadastro')),
  listarTiposCobrancas: React.lazy(() => import('../pages/tipos-cobrancas/lista')),
  tiposCobrancas: React.lazy(() => import('../pages/tipos-cobrancas/cadastro')),
  listarFormasPagamentos: React.lazy(() => import('../pages/formas-pagamentos/lista')),
  formasPagamentos: React.lazy(() => import('../pages/formas-pagamentos/cadastro')),
  relatoriosCustos: React.lazy(() => import('../pages/relatorios-custos')),
  listarValoresMaoObra: React.lazy(() => import('../pages/valores-mao-obra/lista')),
  valoresMaoObra: React.lazy(() => import('../pages/valores-mao-obra/cadastro')),
  listarModeloEtiqueta: React.lazy(() => import('../pages/modelos-etiquetas/lista')),
  modeloEtiqueta: React.lazy(() => import('../pages/modelos-etiquetas/cadastro')),
  marcasVeiculos: React.lazy(() => import('../pages/marcas-veiculos/cadastro')),
  listarMarcasVeiculos: React.lazy(() => import('../pages/marcas-veiculos/lista')),
  listarVisitasTecnicas: React.lazy(() => import('../pages/visitas-tecnicas/lista')),
  visitasTecnicas: React.lazy(() => import('../pages/visitas-tecnicas/cadastro')),
  listarVeiculos: React.lazy(() => import('../pages/veiculos/lista')),
  veiculos: React.lazy(() => import('../pages/veiculos/cadastro')),
  naturezaOperacoes: React.lazy(() => import('../pages/natureza-operacao/cadastro')),
  listarNaturezaOperacoes: React.lazy(() => import('../pages/natureza-operacao/lista')),
  listarManutencaoMaquinasVeiculos: React.lazy(() => import('../pages/manutencao-maquinas-veiculos/lista')),
  manutencaoMaquinasVeiculos: React.lazy(() => import('../pages/manutencao-maquinas-veiculos/cadastro')),
  notasFiscais: React.lazy(() => import('../pages/notas-fiscais/cadastro')),
  listarNotasFiscais: React.lazy(() => import('../pages/notas-fiscais/lista')),
  listarOrdensCompra: React.lazy(() => import('../pages/ordens-compra/lista')),
  ordensCompra: React.lazy(() => import('../pages/ordens-compra/cadastro')),
  ordensCompraImprimir: React.lazy(() => import('../pages/ordens-compra/imprimir')),
  listarPedidos: React.lazy(() => import('../pages/pedidos/lista')),
  pedidos: React.lazy(() => import('../pages/pedidos/cadastro')),
  pedidosImprimir: React.lazy(() => import('../pages/pedidos/imprimir')),
  listarOrcamentos: React.lazy(() => import('../pages/orcamentos/lista')),
  orcamentos: React.lazy(() => import('../pages/orcamentos/cadastro')),
  orcamentosImprimir: React.lazy(() => import('../pages/orcamentos/imprimir')),
  notasEntradas: React.lazy(() => import('../pages/notas-entradas/cadastro')),
  listarNotasEntradas: React.lazy(() => import('../pages/notas-entradas/lista')),
  moedas: React.lazy(() => import('../pages/moedas/cadastro')),
  listarMoedas: React.lazy(() => import('../pages/moedas/lista')),
  cotacoes: React.lazy(() => import('../pages/cotacoes/cadastro')),
  listarCotacoes: React.lazy(() => import('../pages/cotacoes/lista')),
  listasPrecos: React.lazy(() => import('../pages/listas-precos/cadastro')),
  listarListasPrecos: React.lazy(() => import('../pages/listas-precos/lista')),
  codigosBarrasEAN: React.lazy(() => import('../pages/codigos-barras-ean/cadastro')),
  listarCodigosBarrasEAN: React.lazy(() => import('../pages/codigos-barras-ean/lista')),
  promocoes: React.lazy(() => import('../pages/promocoes/cadastro')),
  listarPromocoes: React.lazy(() => import('../pages/promocoes/lista')),
  vasilhames: React.lazy(() => import('../pages/vasilhames/cadastro')),
  listarVasilhames: React.lazy(() => import('../pages/vasilhames/lista')),
  entradaFrutas: React.lazy(() => import('../pages/entrada-frutas/cadastro')),
  listarEntradaFrutas: React.lazy(() => import('../pages/entrada-frutas/lista')),
  faturar: React.lazy(() => import('../components/Faturamento/Faturamento')),
  desistencia: React.lazy(() => import('../components/Faturamento/Desistencia')),
  listarModeloRelatorio: React.lazy(() => import('../pages/modelos-relatorios/lista')),
  modeloRelatorio: React.lazy(() => import('../pages/modelos-relatorios/cadastro')),
  listarTransferenciaDepositos: React.lazy(() => import('../pages/transferencia-depositos/lista')),
  transferenciaDepositos: React.lazy(() => import('../pages/transferencia-depositos/cadastro')),
  listarHistoricosPadrao: React.lazy(() => import('../pages/historicos-padrao/lista')),
  historicosPadrao: React.lazy(() => import('../pages/historicos-padrao/cadastro')),
  litarRegistroEtiquetaEntradaFruta: React.lazy(() => import('../pages/registro-etiquetas-entrada-frutas/lista')),
  registroEtiquetaEntradaFruta: React.lazy(() => import('../pages/registro-etiquetas-entrada-frutas/cadastro')),
  etiquetaPersonalizadaRegistroEtiquetaEntradaFruta: React.lazy(() =>
    import('../pages/registro-etiquetas-entrada-frutas/etiqueta-personalizada'),
  ),
  listarPlanoContas: React.lazy(() => import('../pages/plano-contas/lista')),
  planoContas: React.lazy(() => import('../pages/plano-contas/cadastro')),
  listarMovimentacoesProdutos: React.lazy(() => import('../pages/movimentacao-produtos/lista')),
  movimentacoesProdutos: React.lazy(() => import('../pages/movimentacao-produtos/cadastro')),
  listarContasPagar: React.lazy(() => import('../pages/contas-pagar/lista')),
  contasPagar: React.lazy(() => import('../pages/contas-pagar/cadastro')),
  contasPagarPagamentos: React.lazy(() => import('../pages/contas-pagar/cadastro/pagamento/PagamentoLista')),
  listarPainelSistema: React.lazy(() => import('../pages/painel-sistema')),
  listarContasReceber: React.lazy(() => import('../pages/contas-receber/lista')),
  contasReceber: React.lazy(() => import('../pages/contas-receber/cadastro')),
  contasReceberPagamentos: React.lazy(() => import('../pages/contas-receber/cadastro/pagamento/PagamentoLista')),
  baixaMultipla: React.lazy(() => import('../components/Financeiro/BaixaMultipla/BaixaMultiplaLista')),
  baixaPorValor: React.lazy(() => import('../components/Financeiro/BaixaPorValor/BaixaPorValorForm')),
  transferenciasCaixaBancos: React.lazy(() => import('../pages/transferencias-caixa-bancos/cadastro')),
  listarTransferenciasCaixaBancos: React.lazy(() => import('../pages/transferencias-caixa-bancos/lista')),
  replicacaoTitulo: React.lazy(() => import('../components/Financeiro/Replicar')),
  listarCaixasBancos: React.lazy(() => import('../pages/caixa-bancos/lista')),
  caixaBancos: React.lazy(() => import('../pages/caixa-bancos/cadastro')),
  listarNotasFiscaisConsumidor: React.lazy(() => import('../pages/notas-fiscais-consumidor/lista')),
  notasFiscaisConsumidor: React.lazy(() => import('../pages/notas-fiscais-consumidor/cadastro')),
  configuracoesListar: React.lazy(() => import('../pages/configuracoes/lista')),
  configuracoesCadastro: React.lazy(() => import('../pages/configuracoes/cadastro')),
  listarOcorrenciasFinanceiras: React.lazy(() => import('../pages/ocorrencias-financeiras/lista')),
  ocorrenciasFinanceiras: React.lazy(() => import('../pages/ocorrencias-financeiras/cadastro')),
  contasReceberOcorrencias: React.lazy(() => import('../pages/contas-receber/cadastro/ocorrencia-financeira/index')),
  contasPagarOcorrencias: React.lazy(() => import('../pages/contas-pagar/cadastro/ocorrencia-financeira/index')),
  listarMotivosCancelamentosContratos: React.lazy(() => import('../pages/motivos-cancelamentos-contratos/lista')),
  motivosCancelamentosContratos: React.lazy(() => import('../pages/motivos-cancelamentos-contratos/cadastro')),
  listarContratos: React.lazy(() => import('../pages/contratos/lista')),
  contratos: React.lazy(() => import('../pages/contratos/cadastro')),
  notasFiscaisServicos: React.lazy(() => import('../pages/notas-fiscais-servico/cadastro')),
  listarNotasFiscaisServicos: React.lazy(() => import('../pages/notas-fiscais-servico/lista')),
  contratosOcorrencias: React.lazy(() => import('../pages/contratos/cadastro/ocorrencia/index')),
  listarTributacoesServicos: React.lazy(() => import('../pages/tributacoes-servicos/lista')),
  tributacoesServicos: React.lazy(() => import('../pages/tributacoes-servicos/cadastro')),
  aliquotasImpostosServicos: React.lazy(() => import('../pages/aliquotas-impostos-servicos/cadastro')),
  listarAliquotasImpostosServicos: React.lazy(() => import('../pages/aliquotas-impostos-servicos/lista')),
  contratoSituacao: React.lazy(() => import('../pages/contratos/cadastro/situacao')),
  setoresMesas: React.lazy(() => import('../pages/setores-mesas/cadastro')),
  listarSetoresMesas: React.lazy(() => import('../pages/setores-mesas/lista')),
  mesasComandas: React.lazy(() => import('../pages/mesas-comandas/cadastro')),
  listarMesasComandas: React.lazy(() => import('../pages/mesas-comandas/lista')),
  aberturaFechamentoPDV: React.lazy(() => import('../pages/abertura-fechamento-pdv/cadastro')),
  listarAberturaFechamentoPDV: React.lazy(() => import('../pages/abertura-fechamento-pdv/lista')),
  listarNotificacoes: React.lazy(() => import('../pages/notificacoes/lista')),
  notificacoes: React.lazy(() => import('../pages/notificacoes/cadastro')),
  emitirEmails: React.lazy(() => import('../pages/emails/cadastro')),
};

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      const usuarioAutenticado = isUserAuthenticated();
      if (!usuarioAutenticado) {
        return <Redirect to={{ pathname: '/account/login', state: { from: props.location } }} />;
      }
      return <Component {...props} />;
    }}
  />
);

// root routes
const rootRoutes = [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/dashboard" />,
    route: PrivateRoute,
  },
  {
    path: '/dashboard',
    exact: true,
    component: loggedRoutes.dashboard,
    route: PrivateRoute,
  },
];

// public routes
const publicRoutes = [
  {
    path: '/rastreabilidade/:colheitaId',
    component: RastreabilidadeAgro,
    exact: true,
  },
  {
    path: '/rastreabilidade/propriedade/:propriedadeId',
    component: RastreabilidadePropriedade,
    exact: true,
  },
];

const getProgramaRoute = (programasPermitidos) => {
  let prog = programas.filter((p) =>
    p.children.find((c) =>
      programasPermitidos.find(
        (pp) => pp.codigo === c.programaCod || c?.children?.find((c2) => pp.codigo === c2.programaCod),
      ),
    ),
  );
  const children = prog.map((p) => {
    return {
      ...p,
      children: p.children.filter((c) =>
        programasPermitidos.find(
          (pp) => pp.codigo === c.programaCod || c?.children?.find((c2) => pp.codigo === c2.programaCod),
        ),
      ),
    };
  });

  return children.map((child) => {
    return {
      ...child,
      children: child.children.map((c) => {
        return {
          ...c,
          children: c.children?.filter((c2) => {
            if (!c2.programaCod) return c2;
            else {
              return programasPermitidos.find((pp) => pp.codigo === c2.programaCod);
            }
          }),
        };
      }),
    };
  });
};

const routes = () => {
  const token = getToken();
  const roles = token ? jwt_decode(token)?.roles || [] : [];
  let programasPermitidos = [];
  let programas = getUserPermissions() || [];
  for (let i = 0; i < programas.length; i++) {
    programasPermitidos.push(programas[i]);
  }
  programasPermitidos = [...new Set(programasPermitidos)];

  /* Admin domper tem acesso ao menu sem restrições de itens */
  const programaRoute = getProgramaRoute(programasPermitidos);

  return programaRoute
    .filter((p) => {
      if (roles.find((role) => p.roles.includes(role.codigo))) {
        return true;
      }
      return false;
    })
    .map((p) => {
      return {
        name: p.name,
        icon: p.icon,
        children: p.children && [
          ...p.children.map((child) => {
            return {
              ...child,
              component: loggedRoutes[child.component],
              children: child.children && [
                ...child.children.map((child) => {
                  return {
                    ...child,
                    children: child.children && [
                      ...child.children.map((child) => {
                        return {
                          ...child,
                          component: loggedRoutes[child.component],
                          route: PrivateRoute,
                        };
                      }),
                    ],
                    component: loggedRoutes[child.component],
                    route: PrivateRoute,
                  };
                }),
              ],
              route: PrivateRoute,
            };
          }),
        ],
      };
    });
};

const authRoutes = {
  path: '/account',
  name: 'Auth',
  children: [
    {
      path: '/account/login',
      name: 'Login',
      component: Login,
      route: Route,
    },
    {
      path: '/account/auth2fact',
      name: 'Autenticação em 2 fatores',
      component: AuthDoisFatores,
      route: Route,
    },
    {
      path: '/account/selecionar_empresa',
      name: 'SelecionarEmpresa',
      component: SelecionarEmpresa,
      route: PrivateRoute,
    },
    {
      path: '/account/logout',
      name: 'Logout',
      component: Logout,
      route: Route,
    },
    {
      path: '/account/forget-password',
      name: 'Forget Password',
      component: ForgetPassword,
      route: Route,
    },
    {
      path: '/reset-password',
      name: 'Reset de senha',
      component: ResetPassword,
      route: Route,
    },
    {
      path: '/alterar-senha',
      name: 'Alterar Senha',
      component: AlterarSenha,
      route: Route,
    },
    {
      path: '/acesso-rapido',
      name: 'Acesso Rápido',
      component: AcessoRapido,
      route: Route,
    },
    {
      path: '/perfil',
      name: 'Perfil',
      component: Perfil,
      route: Route,
    },
  ],
};

// flatten the list of all nested routes
const flattenRoutes = (routes) => {
  let flatRoutes = [];

  routes = routes || [];
  routes.forEach((item) => {
    flatRoutes.push(item);

    if (typeof item.children !== 'undefined') {
      flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
    }
  });
  return flatRoutes;
};

// All routes
const allRoutes = () => [...rootRoutes, authRoutes, ...routes()];

const authProtectedRoutes = (userRole) => routes(userRole);
const allFlattenRoutes = (userRole) => flattenRoutes(allRoutes(userRole));
export { allFlattenRoutes, allRoutes, authProtectedRoutes, publicRoutes };
