import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';

import { ProgramaCodigo } from '../../../constants/enums/programa-codigo.enum';
import { SET_LIMIT_PAGE, SET_TABLE_ORDER } from '../../../redux/table/constants';
import { montarFiltroParaApi } from '../../../utils/api-filter';
import { obterNotificacoesAsync } from '../requests';
import { obterNotificacoesErro, obterNotificacoesSucesso } from './actions';
import { FILTRAR_NOTIFICACOES, LISTAR_NOTIFICACOES } from './constants';

function* listarTodosNotificacoes() {
  try {
    const filtro = yield select((state) => state.Notificacoes.filtros);

    let query = { filtro: montarFiltroParaApi(filtro) };
    if (filtro.limite && filtro.pagina) {
      query.paginacao = {
        pagina: filtro?.pagina || 1,
        limite: filtro?.limite || 50,
      };
    }
    const { data } = yield call(obterNotificacoesAsync, query);
    yield put(obterNotificacoesSucesso(data));
  } catch (error) {
    yield put(obterNotificacoesErro(error.response?.data?.message || error.message));
  }
}

function* ordenarNotificacoes({ payload }) {
  if (payload.programaId === ProgramaCodigo.Notificacoes) {
    yield call(listarTodosNotificacoes);
  }
}

function* mudarLimitePaginacao({ payload }) {
  if (payload.programaId === ProgramaCodigo.Notificacoes) {
    yield call(listarTodosNotificacoes);
  }
}

export function* watchListarNotificacoes() {
  yield takeLatest(LISTAR_NOTIFICACOES, listarTodosNotificacoes);
}

export function* watchFiltrarNotificacoes() {
  yield takeLatest(FILTRAR_NOTIFICACOES, listarTodosNotificacoes);
}

export function* watchOrdenarNotificacoes() {
  yield takeLatest(SET_TABLE_ORDER, ordenarNotificacoes);
}

export function* watchLimitePaginacao() {
  yield takeLatest(SET_LIMIT_PAGE, mudarLimitePaginacao);
}

function* NotificacoesSaga() {
  yield all([
    fork(watchListarNotificacoes),
    fork(watchFiltrarNotificacoes),
    fork(watchOrdenarNotificacoes),
    fork(watchLimitePaginacao),
  ]);
}

export default NotificacoesSaga;
