import {
  FILTRAR_NOTIFICACOES,
  LISTAR_NOTIFICACOES,
  LISTAR_NOTIFICACOES_ERRO,
  LISTAR_NOTIFICACOES_SUCESSO,
} from './constants';

export const obterTodosNotificacoes = () => ({
  type: LISTAR_NOTIFICACOES,
});

export const obterNotificacoesSucesso = (notificacao) => ({
  type: LISTAR_NOTIFICACOES_SUCESSO,
  payload: notificacao,
});

export const obterNotificacoesErro = (mensagem) => ({
  type: LISTAR_NOTIFICACOES_ERRO,
  payload: mensagem,
});

export const filtrarNotificacoes = (filtros) => ({
  type: FILTRAR_NOTIFICACOES,
  payload: filtros,
});
