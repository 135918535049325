import { IconBase } from 'react-icons';
import Mask from 'react-text-mask';
import { Button, Input as input } from 'reactstrap';
import styled, { css, keyframes } from 'styled-components';
type justify = 'center' | 'space-between' | 'flex-start' | 'flex-end';

interface IMargin {
  marginTop?: number;
  marginBottom?: number;
  verticalMargin?: number;
  margin?: number;
}

interface IWidth {
  block?: boolean;
  width?: string;
}

interface IFont {
  fontSize?: number;
  bold?: boolean;
}

interface IJustify {
  justify?: justify;
}

const VerticalMargin = css<IMargin>`
  margin: ${({ verticalMargin }) => `${verticalMargin}px 0 ${verticalMargin}px 0`};
`;

const Margin = css<IMargin>`
  margin: ${({ margin }) => `${margin}px`};
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 95vh;
`;

export const Content = styled.div`
  width: fit-content;
  background-color: white;
  padding: 1.25em;
  border-radius: 4px;
  box-shadow: 1px 0.05rem 0.01rem rgb(75 75 90 / 8%);
`;

export const BoxContent = styled.div`
  border: 1px solid rgb(200, 200, 200);
  border-radius: 4px;
  padding: 20px;
  height: 100%;
  @media (max-width: 600px) {
    padding: 10px;
  }
`;

export const FieldSet = styled.fieldset`
  border: 1px solid rgb(200, 200, 200);
  border-radius: 4px;
  padding: 10px 20px;
  height: 100%;

  legend {
    font-size: 0.9rem;
    font-weight: bold;
    padding: 0 5px;
    width: inherit;
  }

  @media (max-width: 600px) {
    padding: 10px;
  }
`;

export const Column = styled.div<IJustify>`
  display: flex;
  flex-direction: column;
  align-items: ${({ justify }) => (justify ? justify : 'flex-start')} !important;
`;

export const Row = styled.div<IMargin & IJustify & IWidth>`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : 0)}px;
  margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : 0)}px;
  ${({ verticalMargin }) => verticalMargin && VerticalMargin}
  ${({ margin }) => margin && Margin}
  justify-content: ${({ justify }) => (justify ? justify : 'flex-start')} !important;
  width: ${({ block, width }) => (block ? '100%' : width ? width : 'auto')};

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

export const NormalText = styled.span<IFont>`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : 22)}px;
  font-weight: ${({ bold }) => bold && 'bold'};
  width: fit-content;
`;

export const Title = styled.h1<IFont>`
  font-size: ${({ fontSize }) => fontSize && `${fontSize}px`};
  font-weight: ${({ bold }) => !bold && 'bold'};
  text-align: center;
`;

export const Radio = styled.input.attrs({
  type: 'radio',
})`
  margin-right: 10px;
  transform: scale(1.5);
`;

export const Input = styled(input)<IWidth & { min?: string }>`
  width: 100%;
  max-width: ${({ width }) => (width ? width : 'auto')};
  min-width: ${({ min }) => (min ? min : 'auto')};
`;

export const InputFiltro = styled(input)<IWidth & { min?: string }>`
  @media only screen and (max-width: 768px) {
    width: 61vw;
  }
`;

export const SelectFiltro = styled(input)<IWidth & { min?: string }>`
  width: 160%;

  @media only screen and (max-width: 1750px) {
    width: 140%;
  }

  @media only screen and (max-width: 1364px) {
    width: 120%;
  }

  @media only screen and (max-width: 1174px) {
    width: 100%;
  }

  @media only screen and (max-width: 768px) {
    width: 72vw;
  }
`;

export const SelectModeloRelatorio = styled(input)<IWidth & { min?: string }>`
  @media only screen and (max-width: 768px) {
    width: 45vw;
  }
`;

export const ButtomRelatorio = styled(Button)<IWidth & { min?: string }>`
  @media only screen and (max-width: 768px) {
    margin-left: 125px;
  }
`;

export const InputMask = styled(Mask)<IWidth & { min?: string }>`
  width: 100%;
  max-width: ${({ width }) => (width ? width : 'auto')};
  min-width: ${({ min }) => (min ? min : 'auto')};
`;

export const TextArea = styled(input).attrs({ type: 'textarea' })`
  min-height: 100px;
  height: 100% !important;
`;

export const Checkbox = styled(input).attrs({ type: 'checkbox' })`
  position: static;
  margin: 0;
  margin: 0 10px 10px 10px;
`;

export const TransparentButton = styled.button`
  background-color: transparent;
  margin: 0;
  padding: 0;
  cursor: pointer;
  border: none;
`;

export const TabNavigation = styled.ul.attrs({
  className: 'nav nav-tabs',
})``;

export const TabItem = styled.li.attrs({
  className: 'nav-item',
})`
  cursor: pointer;
`;

export const TabContent = styled.a.attrs({
  className: 'nav-link',
})``;

export const TabContentActive = styled.a.attrs({
  className: 'nav-link active',
})``;

export const Ul = styled.ul`
  margin: 0;
`;

export const Li = styled.li`
  list-style-type: none;
`;

export const ConteudoListagem = styled.div`
  padding-top: 1.25em;
`;

const cssRotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const SpinIcon = styled(IconBase)`
  display: inline-block;
  animation: ${cssRotate} 2s linear infinite;
`;

interface IEllipsis {
  maxWidth?: number;
}

export const ParagraphWithEllipsis = styled.p<IEllipsis>`
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : '100')}px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  padding: 0;
`;
