import debounce from 'lodash.debounce';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import AsyncSelect from 'react-select/async';
import { apiExceptionHandler } from '../../helpers/exception-handler';
import { State } from '../../redux/reducers';

export interface SelectOption {
  label: string;
  value: string | number;
  codigo?: string;
}

export interface SelecaoAsyncBaseProps {
  carregarOpcoes: (valorBusca: string) => Promise<SelectOption[]>;
  value?: SelectOption | SelectOption[];
  onChange?: (value: any) => void;
  multiple?: boolean;
  disabled?: boolean;
  nomeRecurso?: string;
  showSelectAllOption?: boolean;
  autoFocus?: boolean;
  isClearable?: boolean;
  getOptionLabel?: (option: any) => string;
  maxOptions?: number;
  placeholder?: string;
}

const selectAllOption: SelectOption = { value: 'select-all', label: 'Selecionar Todos' };

const SelecaoAsyncBase: React.FC<SelecaoAsyncBaseProps> = ({
  carregarOpcoes,
  value,
  onChange,
  multiple,
  disabled,
  nomeRecurso,
  autoFocus,
  isClearable = true,
  showSelectAllOption = false,
  maxOptions,
  ...props
}) => {
  const [loadedOptions, setLoadedOptions] = useState<SelectOption[]>([]);
  const [filteredOptions, setFilteredOptions] = useState<SelectOption[]>([]);

  useEffect(() => {
    return () => {
      setLoadedOptions([]);
      setFilteredOptions([]);
    };
  }, []);

  const loadOptions = useCallback(
    debounce((inputText, callback) => {
      carregarOpcoes(inputText)
        .then((data) => {
          const options = showSelectAllOption && multiple && data.length > 1 ? [selectAllOption, ...data] : data;

          if (inputText?.length) {
            setFilteredOptions(data);
          } else {
            setLoadedOptions(data);
          }

          callback(options);
        })
        .catch((error) => apiExceptionHandler(error, `Ocorreu um erro ao preencher o campo ${nomeRecurso}!`));
    }, 500),
    [],
  );

  const handleChange = (currentValues: SelectOption[], { option }) => {
    if (showSelectAllOption && multiple) {
      if (option?.value === selectAllOption.value) {
        if (filteredOptions.length > 0) {
          /* utiliza as options que vieram filtradas pelo input */

          /* remove a opção selecionar todos do currentValues */
          const filteredCurrentValues = currentValues?.filter((option) => option.value !== selectAllOption.value);
          /* remove registros duplicados caso venha no filtro e já esteja selecionado no combo  */
          const mergedFilteredOptions = filteredOptions.filter(
            (option) => !filteredCurrentValues.find((curr) => curr.value === option.value),
          );
          const options = filteredCurrentValues.concat(mergedFilteredOptions);

          setFilteredOptions([]);

          onChange && onChange(options);
        } else {
          /* utiliza as options que foram carregadas no 1º render */
          onChange && onChange(loadedOptions);
        }
      } else {
        /* se selecionar uma opção normal */
        onChange && onChange(currentValues);
      }
    } else {
      /* se o combo não mostra a opção selecionar todos */
      onChange && onChange(currentValues);
    }
  };

  const { coresTema } = useSelector((state: State) => state.Layout);

  const selectRef = React.useRef<HTMLInputElement>(null);

  const handleMaxOptions = () => {
    if (multiple && maxOptions && Array.isArray(value)) {
      return value.length >= maxOptions;
    }

    return false;
  };

  useEffect(() => {
    setTimeout(() => {
      if (autoFocus) {
        selectRef.current?.focus();
      }
    }, 1);
  }, [autoFocus]);

  return (
    <AsyncSelect
      classNamePrefix="domper-select"
      ref={selectRef}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: coresTema.corBotaoPrimario ? coresTema.corBotaoPrimario : theme.colors.primary,
          primary25: coresTema.corBotaoPrimario ? coresTema.corBotaoPrimario + '3D' : theme.colors.primary25,
          primary50: coresTema.corBotaoPrimario ? coresTema.corBotaoPrimario + '5D' : theme.colors.primary50,
        },
      })}
      placeholder={props.placeholder || 'Busque por código ou nome'}
      loadingMessage={() => 'Carregando...'}
      noOptionsMessage={() => 'Sem registros'}
      isDisabled={disabled}
      isClearable={isClearable}
      defaultOptions
      loadOptions={loadOptions}
      onChange={handleChange}
      value={value}
      isMulti={multiple}
      menuPlacement={'auto'}
      menuPortalTarget={document.body}
      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
      closeMenuOnScroll={(e) => ['drawer-container', 'wrapper'].includes(e.target.id)}
      isOptionDisabled={handleMaxOptions}
      {...props}
    />
  );
};

export default SelecaoAsyncBase;
