import { AxiosResponse } from 'axios';
import api from '../../services/api';

export const loginAsync = (login: string, password: string): Promise<AxiosResponse> =>
  api.request({
    url: `auth/login`,
    method: 'POST',
    data: {
      login,
      password,
    },
  });

export const getConfigAsync = (host: string): Promise<AxiosResponse> => {
  return api.request({
    url: `revenda/config/${host}`,
    method: 'GET',
  });
};

export const resetSenhaAsync = (login: string): Promise<AxiosResponse> =>
  api.request({
    url: `usuario/forgot-password`,
    method: 'POST',
    data: {
      login,
    },
  });

export const trocarSenhaAsync = (token: string, password: string): Promise<AxiosResponse> =>
  api.request({
    url: `usuario/reset-password`,
    method: 'PATCH',
    data: {
      token,
      password,
    },
  });

export const trocarSenhaPrimeiroAcessoAsync = ({ senha, senhaAntiga, confirmacaoSenha }) =>
  api.request({
    url: `usuario/primeiro-acesso`,
    method: 'PATCH',
    data: {
      senha,
      senhaAntiga,
      confirmacaoSenha,
    },
  });

export const auth2FatoresEmail = (login: string, password: string): Promise<AxiosResponse> =>
  api.request({
    url: `auth/dois_fatores_email`,
    method: 'POST',
    data: {
      login,
      password,
    },
  });

export const auth2FatoresSms = (login: string, password: string): Promise<AxiosResponse> =>
  api.request({
    url: `auth/dois_fatores_sms`,
    method: 'POST',
    data: {
      login,
      password,
    },
  });

export const logar2Fatores = (login: string, password: string, codigoDoisFatores: string): Promise<AxiosResponse> =>
  api.request({
    url: `auth/login_dois_fatores`,
    method: 'POST',
    data: {
      codigoDoisFatores,
      login,
      password,
    },
  });

export const selecionarOrganizacaoAsync = (organizacaoId: string): Promise<AxiosResponse> =>
  api.request({
    url: `autorizacao/selecionar-organizacao/${organizacaoId}`,
    method: 'PATCH',
  });

export const selecionarEmpresaAsync = (empresaId: string): Promise<AxiosResponse> =>
  api.request({
    url: `autorizacao/selecionar-empresa/${empresaId}`,
    method: 'PATCH',
  });

export const selecionarSegmentoAsync = (segmentoId: string): Promise<AxiosResponse> =>
  api.request({
    url: `autorizacao/selecionar-segmento/${segmentoId}`,
    method: 'PATCH',
  });

export const obterProgramasPermissoesAsync = () =>
  api.request({
    url: `autorizacao/programas`,
    method: 'GET',
  });

export const obterOrganizacoesAutorizadas = () =>
  api.request({
    url: `autorizacao/organizacoes`,
    method: 'GET',
  });

export const obterEmpresasAutorizadas = () =>
  api.request({
    url: `autorizacao/empresas`,
    method: 'GET',
  });

export const obterSegmentosAutorizadosAsync = () =>
  api.request({
    url: `autorizacao/segmentos`,
    method: 'GET',
  });

export const obterParametrosReduxAsync = (): Promise<AxiosResponse> =>
  api.request({
    url: `autorizacao/parametros-redux`,
    method: 'GET',
  });
