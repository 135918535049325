import { AdminClienteMenus } from './menus/admin-cliente';
import { AdminDomperMenus } from './menus/admin-domper';
import { AdminRevendaMenus } from './menus/admin-revenda';
import { CadastrosMenus } from './menus/cadastros';
import { CadernoCampoMenus } from './menus/cadernoCampo';
import { ComprasMenus } from './menus/compras';
import { CustosMenus } from './menus/custos';
import { EstoqueMenus } from './menus/estoque';
import { FaturamentoMenus } from './menus/faturamento';
import { FinanceirosMenus } from './menus/financeiro';
import { FrutaMenus } from './menus/frutas';
import { MensageriaMenus } from './menus/mensageria';
import { TecnicoDomperMenus } from './menus/tecnico-domper';
import { TecnicoRevendaMenus } from './menus/tecnico-revenda';
import { TecnicoSolucaoMenus } from './menus/tecnico-solucao';
import { VendasMenus } from './menus/vendas';

/*
 * Os menus serão montados de acordo com o objeto pai, caso ele tenha filhos e estes filhos não tiverem nome,
 * a rota no menu continuará sendo do pai, porém passará a existir as rotas filhos sem estarem no menu.
 */

export const programas = [
  AdminDomperMenus,
  AdminRevendaMenus,
  AdminClienteMenus,
  TecnicoDomperMenus,
  TecnicoRevendaMenus,
  TecnicoSolucaoMenus,
  MensageriaMenus,
  CadastrosMenus,
  CadernoCampoMenus,
  ComprasMenus,
  CustosMenus,
  EstoqueMenus,
  FaturamentoMenus,
  FinanceirosMenus,
  FrutaMenus,
  VendasMenus,
];
